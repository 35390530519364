export function InicialSet() {
  //body -> documentElement images-compare-before left
  var page_height = window.innerHeight
  var page_width = window.innerWidth
  var page_filter = window.innerWidth / 2

  var image_before = document.getElementById('images-compare-before')
  var image_handle = document.getElementById('images-compare-handle')
  var image_separator = document.getElementById('images-compare-separator')

  image_before.style.width = page_width + 'px'
  image_before.style.height = page_height + 'px'
  image_before.style.clip =
    'rect(0px, ' + page_filter + 'px, ' + page_height + 'px, 0px)'
  image_handle.style.left = page_filter + 'px'
  image_separator.style.left = page_filter + 'px'
}

export function DraggableButton(x) {
  window.addEventListener('mousemove', (e) => {
    console.log('ola')
    var page_height = window.innerHeight

    var image_before = document.getElementById('images-compare-before')
    var image_handle = document.getElementById('images-compare-handle')
    var image_separator = document.getElementById('images-compare-separator')

    var page_filter = e.clientX

    image_handle.style.left = page_filter + 'px'
    image_separator.style.left = page_filter + 'px'
    image_before.style.clip =
      'rect(0px, ' + page_filter + 'px, ' + page_height + 'px, 0px)'
  })
}
