export function Open_InternMenu() {
  document.getElementById('MenuInterno').classList.add('Show_MenuInterno')
}

export function Close_InternMenu() {
  document.getElementById('MenuInterno').classList.remove('Show_MenuInterno')
}

export function Hide_Info() {
  document.getElementById('Beneficios').classList.add('Hide_Beneficios')
  setTimeout(function () {
    document
      .getElementById('DivBtnVoltaBeneficios')
      .classList.add('Show_DivBtnVoltaBeneficios')
  }, 400)
}

export function Show_Info() {
  document
    .getElementById('DivBtnVoltaBeneficios')
    .classList.remove('Show_DivBtnVoltaBeneficios')

  setTimeout(function () {
    document.getElementById('Beneficios').classList.remove('Hide_Beneficios')
  }, 400)
}

//Procura: Abre
export function Open_Modal() {
  document
    .getElementById('ModalBeneficios')
    .classList.add('Show_ModalBeneficios')

  setTimeout(function () {
    document
      .getElementById('DivModalBeneficios')
      .classList.add('Show_DivModalBeneficios')
  }, 500)
}

//Procura: Fecha
export function Close_Modal() {
  document
    .getElementById('DivModalBeneficios')
    .classList.remove('Show_DivModalBeneficios')

  setTimeout(function () {
    document
      .getElementById('ModalBeneficios')
      .classList.remove('Show_ModalBeneficios')
  }, 500)
}
