<template>
  <div>
    <header class="Header">
      <div class="container-fluid NoPadding">
        <div class="row">
          <div class="col-6 NoPadding HeightHeader_Logo">
            <a href="/lens" id="LogoVoltaMenu">
              <img
                src="@/views/menu/svg/LensExpert___Logo.svg"
                class="LogoMenu"
                alt="LenxXpert"
              />
            </a>
          </div>

          <div class="col-6 NoPadding HeightHeader_Menu">
            <div class="DivBtnVoltarMenu">
              <a href="/lens" class="" id="VoltarAoMenuPrincipal">
                <img
                  src="@/views/menu/svg/SetaVoltarMenu.svg"
                  class="ImagemBtnVoltaMenu"
                  alt="Voltar ao Menu"
                />
              </a>
            </div>

            <div class="DivBtnOpenMenu BackgroundColor1">
              <button
                @click="Open_InternMenu()"
                class="hamburger hamburger--spin"
                type="button"
                id="OpenMenuInterno"
              >
                <span
                  class="hamburger-box d-flex justify-content-center align-items-center"
                >
                  <span class="hamburger-inner"></span>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </header>
    <!--Menu Interno-->
    <div class="MenuInterno BackgroundMenuInterno" id="MenuInterno">
      <a href="/lens" class="BtnChangePages">MENU</a>

      <a
        href="/lens/simple-lens"
        class="BtnChangePages Hide"
        id="Menu_VisaoSimples"
        >VISÃO SIMPLES</a
      >
      <a
        href="/lens/progressive-lens"
        class="BtnChangePages"
        id="Menu_Progressivas"
        >PROGRESSIVAS</a
      >
      <a
        href="/lens/photosensitive"
        class="BtnChangePages"
        id="Menu_Fotossensiveis"
        >FOTOSSENSÍVEIS</a
      >
      <a href="/lens/anti-glare/1" class="BtnChangePages" id="Menu_Antirreflexo"
        >ANTIRREFLEXO</a
      >
      <a
        href="/lens/polarized-lens/1"
        class="BtnChangePages"
        id="Menu_Polarizadas Hide"
        >POLARIZADAS</a
      >
      <a
        href="/lens/occupational-lens"
        class="BtnChangePages"
        id="Menu_Ocupacionais"
        >OCUPACIONAIS</a
      >
      <a href="/lens/sports-lens" class="BtnChangePages" id="Menu_Esportivas"
        >ESPORTIVAS</a
      >
      <a href="/lens/thickness" class="BtnChangePages" id="Menu_Espessuras"
        >ESPESSURAS</a
      >
      <a href="/lens/blue-filter" class="BtnChangePages" id="Menu_FiltroAzul"
        >FILTRO AZUL</a
      >
      <!-- <a href="javascript:void(0)" 
      class="BtnChangePages" 
      id="Menu_Calculos"
        >CÁLCULOS</a  -->

      <a
        @click="Close_InternMenu()"
        href="javascript:void(0)"
        class="BtnCloseMenuInterno"
        id="CloseMenuInterno"
      >
        <img
          src="@/views/menu/svg/CloseMenuInterno.svg"
          class="ImagemBtnCloseMenuInterno"
          alt="FECHAR"
        />
      </a>
    </div>
    <!--Menu Interno-->
  </div>
</template>

<script>
import { Open_InternMenu, Close_InternMenu } from '@/views/menu/js/new/Scripts'

export default {
  methods: {
    Open_InternMenu,
    Close_InternMenu,
  },
}
</script>

<style>
@import '../css/Style.css';
@import '../css/MyStyle.css';
@import '../css/MyLogin.css';
@import '../css/MyColors.css';
@import '../css/MyMenu.css';
@import '../css/MyMenuInterno.css';
</style>
